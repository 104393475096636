
import { reactive } from "vue"
import TabsComponent from "@/components/navigation/TabsComponent.vue"

export default {
  name: "Dicoms",
  components: { TabsComponent },
  props: {
    organization: {},
  },
  setup() {
    const tabItems = reactive([
      { label: "Dicom Application Entities", routeName: "dicomApplicationEntities" },
      { label: "Tag Config", routeName: "dicomtagconfigs" },
      { label: "Mapping Config", routeName: "dicommappingconfigs" },
    ])

    return {
      tabItems,
    }
  },
}
